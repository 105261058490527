import { Component, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { PTZCounterConfig } from './types/ptz-counter.enums';
import { PTZCounterTypes } from './types/ptz-counter.types';

@Component({
  tag: 'ptz-counter',
  styleUrl: 'ptz-counter.scss',
  shadow: false,
})
export class PTZCounter {
  /** Tamanho do Input */
  @Prop() size?: PTZCounterTypes.Size = PTZCounterConfig.Size.Medium;
  /** Mensagem de suporte do Counter */
  @Prop() supportLabel?: string;
  /** Valor máximo do contador */
  @Prop() maxValue?: number = 100;
  /** Valor mínimo do contador */
  @Prop() minValue?: number = 1;
  /** Valor atual do contador */
  @Prop({ mutable: true }) value: number = this.minValue;
  /** Estado de renderização */
  @Prop() skeleton: boolean = false;
  /** Evento que dispara ao decrementar e incrementar */
  @Event({
    eventName: 'countChange',
    composed: true,
    cancelable: true,
    bubbles: true,
  })
  countChange: EventEmitter<PTZCounterTypes.CounterEvent>;

  @State() private currentValue: number = this.value;
  @State() private currentDisabled: PTZCounterTypes.Mode = PTZCounterConfig.Mode.None;

  @Watch('currentValue')
  onCurrentValueChange(newValue: number) {
    if (newValue === this.maxValue && newValue === this.minValue) {
      this.currentDisabled = PTZCounterConfig.Mode.All;
      return this.currentDisabled;
    }
    if (newValue >= this.maxValue) {
      this.currentDisabled = PTZCounterConfig.Mode.Increment;
      return this.currentDisabled;
    }
    if (newValue <= this.minValue) {
      this.currentDisabled = PTZCounterConfig.Mode.Decrement;
      return this.currentDisabled;
    }
    this.currentDisabled = PTZCounterConfig.Mode.None;
    return this.currentDisabled;
  }

  @Watch('value')
  onValueChange(newValue: number) {
    this.currentValue = newValue;
  }

  @Listen('input')
  handleInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const newValue = Number(target.value);
    this.currentValue = newValue;

    this.validateInterval(this.currentValue);

    target.value = this.currentValue.toString();
  }

  private validateInterval(value) {
    if (value < this.minValue) {
      this.currentValue = this.minValue;
    }
    if (this.maxValue != null && value > this.maxValue) {
      this.currentValue = this.maxValue;
    }
  }

  private handleInputFocus(event: FocusEvent) {
    const target = event.target as HTMLInputElement;
    if (target instanceof HTMLInputElement) {
      target.select();
    }
  }

  private handleInputBlur() {
    let newValue: number;
    let mode: PTZCounterTypes.Mode;

    this.validateInterval(this.currentValue);

    if (this.value < this.currentValue) {
      newValue = this.currentValue - this.value;
      mode = PTZCounterConfig.Mode.Increment;
    } else {
      newValue = this.value - this.currentValue;
      mode = PTZCounterConfig.Mode.Decrement;
    }
    this.value = this.currentValue;

    this.countChange.emit({ quantity: newValue, mode, currentValue: this.currentValue });
  }

  private handleChangeValue(mode: PTZCounterTypes.Mode) {
    switch (mode) {
      case PTZCounterConfig.Mode.Increment:
        this.currentValue++;
        this.countChange.emit({ quantity: 1, mode, currentValue: this.currentValue });
        return;
      case PTZCounterConfig.Mode.Decrement:
        this.currentValue--;
        this.countChange.emit({ quantity: 1, mode, currentValue: this.currentValue });
        return;
      default:
        return this.currentValue;
    }
  }

  componentDidLoad() {
    this.onCurrentValueChange(this.currentValue);
  }

  render() {
    const counterStyles = `ptz-counter ptz-counter-${this.size}`;
    const hasSupportLabel = this.supportLabel !== undefined && this.maxValue > 0 && this.currentValue >= this.maxValue;

    const button = (mode: PTZCounterTypes.Mode) => {
      const iconName = mode === 'increment' ? 'plus' : 'minus';
      const ariaLabel = mode === 'increment' ? 'Incrementar' : 'Decrementar';
      const isDisabled = mode === this.currentDisabled || this.currentDisabled === PTZCounterConfig.Mode.All;
      const iconColor = isDisabled ? 'neutral-mid' : 'neutral-black';
      const testId = mode === 'increment' ? `counter-increment` : `counter-decrement`;

      return (
        <button data-testid={testId} disabled={isDisabled} aria-label={ariaLabel} onClick={() => this.handleChangeValue(mode)}>
          <ptz-icon name={iconName} variant="line" size="lg" color={iconColor} brand="ptz"></ptz-icon>
        </button>
      );
    };

    const skeletonheight = {
      sm: 50,
      md: 58,
      lg: 66,
      xlg: 90,
    };

    return (
      <Host>
        {this.skeleton ? (
          <ptz-skeleton height={skeletonheight[this.size]}></ptz-skeleton>
        ) : (
          <div class={counterStyles}>
            {button(PTZCounterConfig.Mode.Decrement)}
            <input
              data-testid={`counter-input`}
              type="number"
              aria-label="Contador"
              max={this.maxValue}
              min={this.minValue}
              value={this.currentValue}
              spellcheck="false"
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur.bind(this)}
            />
            {button(PTZCounterConfig.Mode.Increment)}
          </div>
        )}
        {hasSupportLabel && <span class="ptz-counter-support-label">{this.supportLabel}</span>}
      </Host>
    );
  }
}
