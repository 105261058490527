ptz-alert {
  .ptz-alert {
    display: inline-flex;
    align-items: flex-start;
    position: relative;
    padding: $petz-spacing-sm;
    border-radius: $petz-rounded-sm;

    &-primary {
      background: $petz-color-primary-signature;

      &::before {
        background: $petz-color-primary-dark;
      }

      &-icon i {
        color: $petz-color-primary-dark;
      }

      &-label {
        color: $petz-color-primary-dark;
      }
    }

    &-secondary {
      background: $petz-color-secondary-light;

      &::before {
        background: $petz-color-neutral-black;
      }

      &-icon i {
        color: $petz-color-neutral-black;
      }

      &-label {
        color: $petz-color-neutral-black;
      }
    }

    &-info {
      background: $petz-color-neutral-lighter-accent;

      &::before {
        background: $petz-color-neutral-darker-accent;
      }

      &-icon i {
        color: $petz-color-neutral-darker-accent;
      }

      &-label {
        color: $petz-color-neutral-darker-accent;
      }
    }

    &-success {
      background: $petz-color-sup-green-light;

      &::before {
        background: $petz-color-sup-green-darker-accent;
      }

      &-icon i {
        color: $petz-color-sup-green-darker-accent;
      }

      &-label {
        color: $petz-color-sup-green-darker-accent;
      }
    }

    &-error {
      background: #{$petz-color-sup-red-light}40;

      &::before {
        background: $petz-color-sup-red-darker-accent;
      }

      &-icon i {
        color: $petz-color-sup-red-darker-accent;
      }

      &-label {
        color: $petz-color-sup-red-darker-accent;
      }
    }

    &:before {
      content: '';
      width: 3px;
      height: 24px;
      position: absolute;
      top: $petz-spacing-sm;
      left: 0;
      border-radius: 0 $petz-rounded-max $petz-rounded-max 0;
      opacity: $petz-opacity-md;
    }

    &-icon i {
      display: flex;
    }

    &-label {
      margin: 0;
      padding-left: 10px;
      font-weight: $petz-font-normal;
      line-height: $petz-font-lineheight-lg;
      max-height: 76px;
      overflow: hidden;
    }

    &-small {
      .ptz-alert-label {
        font-size: $petz-font-size-3xs;
      }
    }

    &-large {
      .ptz-alert-label {
        font-size: $petz-font-size-2xs;
      }
    }
  }
}
