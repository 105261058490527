$sizes: (
  "sm": 0.625rem $petz-spacing-sm,
  "md": 0.875rem $petz-spacing-md,
  "lg": 1.125rem $petz-spacing-md,
  "xlg": 1.875rem $petz-spacing-md
);

ptz-counter {
  .ptz-counter {
    border: $petz-border-sm solid $petz-color-neutral-mid;
    border-radius: $petz-rounded-xs;
    display: flex;
    justify-content: space-between;
    color: $petz-color-neutral-black;
    gap: $petz-spacing-3xs;

    &-support-label {
      display: block;
      margin-top: 0.375rem;
      font-weight: $petz-font-weight-bold;
      font-size: $petz-font-size-3xs;
      line-height: $petz-font-lineheight-lg;
      color: $petz-color-neutral-darker-accent;
    }

    @each $size,
    $padding in $sizes {
      &-#{$size} {
        padding: $padding;
      }
    }

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    ptz-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ptz-icon i {
      width: 24px;
      height: 24px;
    }

    input {
      text-align: center;
      width: 100%;
      border: none;
      font-size: $petz-font-size-sm;
      font-weight: $petz-font-weight-black;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      -moz-appearance: textfield;

      &::selection {
        background-color: $petz-color-neutral-light;
      }

      &:focus {
        outline: none;
      }
    }
  }

  ptz-skeleton {
    width: 100%;
  }
}
