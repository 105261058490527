import { Component, h, Host, Prop } from '@stencil/core';
import { getSkeletonWidth } from '../../utils/utils';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZAlertConfig } from './types/ptz-alert.enums';
import { PTZAlertTypes } from './types/ptz-alert.types';

@Component({
  tag: 'ptz-alert',
  styleUrl: 'ptz-alert.scss',
  shadow: false
})

export class PTZAlert {
  /** Nome do ícone no Alerta */
  @Prop() iconName?: PTZIconTypes.Name

  /** Variante do ícone no Alerta */
  @Prop() iconVariant?: PTZIconTypes.Variant

  /** Tipo do Alerta */
  @Prop() kind?: PTZAlertTypes.Kind = PTZAlertConfig.Kind.Primary

  /** Label do Alerta */
  @Prop() label: string

  /** Tamanho do Alerta */
  @Prop() size?: PTZAlertTypes.Size = PTZAlertConfig.Size.Large

   /** Estado de renderização */
   @Prop() skeleton: boolean = false

   /** TestId para o componente */
   @Prop() testId: string

  render() {
    const containerClass = { 'ptz-alert': true, [`ptz-alert-${this.kind}`]: Boolean(this.kind), [`ptz-alert-${this.size}`]: Boolean(this.size) };
    const iconClass = { 'ptz-alert-icon': true, [`ptz-alert-${this.kind}-icon`]: Boolean(this.kind) };
    const labelClass = { 'ptz-alert-label': true, [`ptz-alert-${this.kind}-label`]: Boolean(this.kind) };

    const fixedWidth = 50
    const intervals = [
      { max: 120, w: 930 },
      { max: 60, w: 565 },
      { max: 30, w: 315 },
      { max: 15, w: 175 },
      { max: 7, w: 75 },
    ];

    return (
      <Host>
        {this.skeleton ? (
          <ptz-skeleton width={getSkeletonWidth(this.label.length, intervals, fixedWidth)} height={52} rounded="md" />
        ) : (
          <div role="alert" class={containerClass}>
            <ptz-icon class={iconClass} name={this.iconName} variant={this.iconVariant} size="md" />
            <p data-testid={this.testId} class={labelClass}>
              {this.label}
            </p>
          </div>
        )}
      </Host>
    );
  }
}
