import { Component, Event, EventEmitter, Host, Prop, State, h } from '@stencil/core';
import { formatedPrice } from '../../../../utils/utils';
import { PTZHeaderDrawerBagConfig } from '../ptz-header-drawer-bag/types/ptz-header-drawer-bag.enums';
import { PTZHeaderDrawerBagTypes } from '../ptz-header-drawer-bag/types/ptz-header-drawer-bag.types';

@Component({
  tag: 'ptz-header-drawer-bag-card',
  styleUrl: 'ptz-header-drawer-bag-card.scss',
  shadow: false,
})
export class PTZHeaderDrawerBagCard {
  /** Lista dos detalhes do produto */
  @Prop() bagProductDetails: PTZHeaderDrawerBagTypes.Item;

  /** Posição do produto */
  @Prop() productPosition: number;

  /** Método que remove um produto  */
  @Prop() removeProductHandler: (product: PTZHeaderDrawerBagTypes.Item, position: number, quantity: number, sku: string) => void;

  /** Método que muda a quantidade de produto */
  @Prop() quantityChangeHandler: (event: CustomEvent, product: PTZHeaderDrawerBagTypes.Item, position: number, sku: string) => void;

  /** Método que abre a opção de deletar o produto */
  @Prop() trashIconHandler: (sku: string) => void;

  /** Informação sobre o carregamento */
  @Prop() isLoading?: PTZHeaderDrawerBagTypes.BagLoad | null;

  /** Recebe informação sobre possíveis erros */
  @Prop() hasError?: PTZHeaderDrawerBagTypes.BagError | null;

  /** Guarda qual o sku do produto que o usuário deseja excluir */
  @Prop() trashedSkuProduct: string = '';
  
  /** Retorna se o produto está disponível ou não */
  @Prop() isAvailable?: boolean = false;

  /** O produto foi deletado pelo usuário ou não */
  @State() isTrashedProduct: boolean = false;

  /** Evento que redireciona para o link do produto */
  @Event({
    eventName: 'productRedirectHandler',
    composed: true,
  }) productRedirectHandler: EventEmitter<{product: PTZHeaderDrawerBagTypes.Item, position: number}>;
  
  /** Evento que envia o SKU do produto removido na sacola */
  @Event({
    eventName: 'getDeletedProductSku',
    composed: true,
  }) getDeletedProductSku: EventEmitter<{productSku: string}>;

  private isLoadingDeleteProduct(): boolean {
    return !!this.isLoading && this.isLoading.type === PTZHeaderDrawerBagConfig.Load.Delete;
  }

  private isLoadingGetAfterDeleteProduct(): boolean {
    return !!this.isLoading && this.isLoading.type === PTZHeaderDrawerBagConfig.Load.GetDelete;
  }

  private isDeleteError(): boolean {
    return !!this.hasError && this.hasError.type === PTZHeaderDrawerBagConfig.Error.Delete && this.hasError.sku === this.bagProductDetails?.sku;
  }

  private isIncrementOrDecrementProductError(): boolean {
    return (
      this.hasError &&
      (this.hasError.type === PTZHeaderDrawerBagConfig.Error.Decrement || this.hasError.type === PTZHeaderDrawerBagConfig.Error.Increment) &&
      this.bagProductDetails?.sku === this.hasError.sku
    );
  }

  private skuProductEqualTrashedSkuProduct(): boolean {
    return this.bagProductDetails?.sku === this.trashedSkuProduct;
  }

  private getCardClassNameStyle(): { [key: string]: boolean } {
    return {
      'collapse-card-product': this.skuProductEqualTrashedSkuProduct() && this.isLoadingGetAfterDeleteProduct() && this.isTrashedProduct,
    };
  }

  private getDeleteClassNameStyle(): { [key: string]: boolean } {
    return {
      'show-delete-button': this.isDeleteError() || this.skuProductEqualTrashedSkuProduct(),
      'delete-product-error': this.skuProductEqualTrashedSkuProduct() && this.isDeleteError() && this.isTrashedProduct,
      'delete-product-success': this.skuProductEqualTrashedSkuProduct() && this.isLoadingDeleteProduct() && this.isTrashedProduct,
    };
  }

  private onRedirectProduct(): void {
    this.productRedirectHandler.emit({product: this.bagProductDetails, position: this.productPosition});
  }

  private variationBadge(): JSX.Element | undefined {
    const { variationName, variationDescription } = this.bagProductDetails || {};
    if (!variationName) return undefined;
    return (
      <div class="ptz-header-drawer-bag-card-content-variation">
        <p aria-label={variationDescription} title={variationDescription}>
          {variationName}
        </p>
      </div>
    );
  }

  private comboBadge(): JSX.Element | undefined {
    return (
      this.bagProductDetails?.combo && (
        <div class="ptz-header-drawer-bag-card-content-badge">
          <ptz-badge color={'sup-green-light'} iconAlign={'left'} iconName={'grin-tongue-wink'} iconVariant={'line'} size={'md'} variant={'solid'}>
            Produto Combo
          </ptz-badge>
        </div>
      )
    );
  }

  private subscriptionBadge(): JSX.Element | undefined {
    const subscriptionPeriod = this.bagProductDetails?.subscriptionPeriod ?? 0;

    return (
      subscriptionPeriod > 0 && (
        <div class="ptz-header-drawer-bag-card-content-badge">
          <ptz-badge color={'primary-signature'} iconAlign={'left'} iconName={'petz-subscription'} iconVariant={'line'} size={'md'} variant={'solid'}>
            Envio a cada {subscriptionPeriod} dias
          </ptz-badge>
        </div>
      )
    );
  }

  private incrementOrDecrementAlertError(): JSX.Element | undefined {
    return (
      this.isIncrementOrDecrementProductError() && (
        <ptz-alert kind="error" size="large" icon-variant="line" icon-name="info-circle" label="Não foi possível alterar a quantidade deste produto. Tente novamente." />
      )
    );
  }

  private deleteProduct(quantity: number, productSku: string) {
    this.isTrashedProduct = true;
    this.removeProductHandler(this.bagProductDetails, this.productPosition, quantity, productSku);
    this.getDeletedProductSku.emit({productSku});
  }

  private deleteAlertError(): JSX.Element | undefined {
    return (
      this.isDeleteError() && <ptz-alert kind="error" size="large" icon-variant="line" icon-name="info-circle" label="Não conseguimos excluir o produto. Vamos lá, tente de novo." />
    );
  }

  private discountPrice(): JSX.Element | undefined {
    const previousPrice = this.bagProductDetails?.previousPrice ?? 0;
    const hasDiscount = previousPrice > 0 && previousPrice !== this.bagProductDetails.currentPrice;
    const formatedPreviousPrice = formatedPrice(previousPrice);

    return hasDiscount && <p class="ptz-header-drawer-bag-card-content-price-discount">{formatedPreviousPrice}</p>;
  }

  render() {
    const { currentPrice, sku: productSku, id: productId, name, thumbnail, quantity, totalStock } = this.bagProductDetails ?? {};
    const formatedCurrentPrice = formatedPrice(currentPrice);

    return (
      <Host key={productId}>
        <div class={{ 'ptz-header-drawer-bag-card': true, ...this.getCardClassNameStyle() }}>
          <img
            class={{ 'ptz-header-drawer-bag-card-image': true, ...this.getDeleteClassNameStyle() }}
            onClick={() => this.onRedirectProduct()}
            src={thumbnail}
            alt="Imagem do produto"
          />
          <div class={{ 'ptz-header-drawer-bag-card-content': true, ...this.getDeleteClassNameStyle() }} onClick={() => this.trashIconHandler('')}>
            <p class="ptz-header-drawer-bag-card-content-title" onClick={() => this.onRedirectProduct()}>
              {name}
            </p>
            <span class="ptz-header-drawer-bag-card-content-price">
              <p class="ptz-header-drawer-bag-card-content-price-total">{formatedCurrentPrice}</p>
              {this.discountPrice()}
            </span>
            {this.comboBadge()}
            {this.subscriptionBadge()}
            {this.isAvailable && (
              <div class="ptz-header-drawer-bag-card-content-quantity">
                <ptz-counter
                  size={'sm'}
                  value={quantity}
                  minValue={1}
                  maxValue={totalStock}
                  supportLabel="Essa é a quantidade máxima disponível para compra."
                  onCountChange={event => {
                    event.stopPropagation();
                    this.quantityChangeHandler(event, this.bagProductDetails, this.productPosition, productSku);
                  }}
                />
                {this.variationBadge()}
              </div>
            )}
          </div>
          {!this.isAvailable && (
            <div class="ptz-header-drawer-bag-card-unavailable">
              <ptz-alert label={`O produto ${name} encontra-se indisponível no momento.`} kind="error" size="large" iconName="info-circle" iconVariant="line" />
            </div>
          )}
          <button class={{ 'ptz-header-drawer-bag-card-trash-icon': true, ...this.getDeleteClassNameStyle() }} onClick={() => this.trashIconHandler(productSku)}>
            <ptz-icon name="petz-trash" variant="line" color="neutral-black" size="lg"></ptz-icon>
          </button>
          <button
            class={{ 'ptz-header-drawer-bag-card-delete-button': true, ...this.getDeleteClassNameStyle() }}
            id={`deleteButton_${productSku}`}
            onClick={() => this.deleteProduct(quantity, productSku)}
          >
            <p>Excluir da sacola</p>
          </button>
          {this.incrementOrDecrementAlertError()}
          {this.deleteAlertError()}
        </div>
      </Host>
    );
  }
}
