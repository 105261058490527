import { EnumToArray } from '../../../utils/enumToArray';

enum Kind {
  Primary = 'primary',
  Secondary = 'secondary',
  Info = 'info',
  Success = 'success',
  Error = 'error',
}

enum Size {
  Small = 'small',
  Large = 'large',
}

export const PTZAlertConfigList = {
  Kind: EnumToArray([Kind]),
  Size: EnumToArray([Size]),
};

export const PTZAlertConfig = {
  Kind,
  Size,
};
