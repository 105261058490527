@mixin transition-translate($x) {
  transform: translateX($x);
  transition: transform 300ms ease-out;
}

@keyframes move-from-left-to-right-card {
  from {
    @include transition-translate(-6rem);
  }
  to {
    @include transition-translate(31.25rem);
  }
}

@keyframes move-from-right-to-left-card {
  from {
    @include transition-translate(31.25rem);
  }
  to {
    @include transition-translate(0);
  }
}

@keyframes move-from-left-to-right-delete {
  from {
    right: 0.75rem;
  }
  to {
    right: -10rem;
  }
}

@keyframes move-from-right-to-left-delete {
  from {
    right: -10rem;
  }
  to {
    right: -5.3125rem;
  }
}

@keyframes collapse-card {
  from {
    height: auto;
  }
  to {
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-top-width: 0;
    border-bottom-width: 0;
  }
}

ptz-header-drawer-bag-card {
  position: relative;

  .ptz-header-drawer-bag-card {
    display: grid;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    grid-template-rows: 1fr;
    grid-row-gap: $petz-spacing-sm;
    grid-template-columns: 1fr 2fr 1fr;
    padding: $petz-spacing-md $petz-spacing-lg;
    border-bottom: $petz-border-sm solid $petz-color-neutral-light;

    &.exceed-quantity {
      grid-template-rows: 1fr 3.25rem;
    }

    .show-delete-button {
      @include transition-translate(-5rem);
    }

    &.collapse-card-product {
      opacity: 0;
      animation: collapse-card 500ms ease-in-out forwards;
    }

    .delete-product-success {
      animation: move-from-left-to-right-card 500ms ease-in-out forwards;
    }

    .delete-product-error {
      animation: move-from-right-to-left-card 500ms ease-in-out forwards;
    }

    &-image {
      cursor: pointer;
      grid-area: 1 / 1 / 2 / 2;
      box-sizing: border-box;
      max-width: $petz-spacing-5xl;
      max-height: $petz-spacing-5xl;
      @include transition-translate(0);
    }

    &-content {
      grid-area: 1 / 2 / 2 / 3;
      padding: 0 $petz-spacing-xs 0 $petz-spacing-sm;
      @include transition-translate(0);

      p {
        margin: 0;
      }

      &-title {
        cursor: pointer;
        width: 18.4375rem;
        font-weight: $petz-font-weight-regular;
        font-size: $petz-font-size-xs;
        line-height: $petz-font-lineheight-lg;
        color: $petz-color-neutral-darker-accent;
      }

      &-price {
        display: flex;
        align-items: baseline;
        margin-top: $petz-spacing-xs;

        &-total {
          font-weight: $petz-font-weight-bold;
          font-size: $petz-font-size-xs;
          padding-right: $petz-spacing-3xs;
          line-height: $petz-font-lineheight-lg;
          color: $petz-color-neutral-darker-accent;
        }

        &-discount {
          font-weight: $petz-font-weight-bold;
          font-size: $petz-font-size-3xs;
          color: $petz-color-neutral-dark;
          text-decoration-line: line-through;
          line-height: $petz-font-lineheight-lg;
        }
      }

      &-badge {
        margin-top: $petz-spacing-xs;
      }

      &-quantity {
        display: flex;
        margin-top: $petz-spacing-xs;

        ptz-counter {
          width: 10rem;
        }
      }

      &-variation {
        display: flex;
        align-items: center;
        height: fit-content;
        font-weight: $petz-font-weight-bold;
        font-size: $petz-font-size-2xs;
        margin-left: $petz-spacing-2xs;
        border-radius: $petz-rounded-xs;
        padding: 0.875rem $petz-spacing-xs;
        line-height: $petz-font-lineheight-lg;
        color: $petz-color-neutral-darker-accent;
        border: $petz-border-sm solid $petz-color-neutral-mid;
      }
    }

    &-trash-icon {
      grid-area: 1 / 3 / 2 / 4;
      border: none;
      display: flex;
      padding-left: 0;
      cursor: pointer;
      height: fit-content;
      background: transparent;
      margin-right: $petz-spacing-sm;
      @include transition-translate(0);
    }

    &-delete-button {
      top: 0;
      bottom: 0;
      border: none;
      display: flex;
      cursor: pointer;
      position: absolute;
      text-align: center;
      right: -5.3125rem;
      width: 5.3125rem;
      flex-direction: column;
      justify-content: center;
      font-weight: $petz-font-weight-bold;
      font-size: $petz-font-size-2xs;
      color: $petz-color-neutral-white;
      background: $petz-color-sup-red-dark;
      line-height: $petz-font-lineheight-lg;
      transition: all 300ms ease-out;

      &.show-delete-button {
        right: 0;
        transform: none;
        transition: all 300ms ease-out;
      }

      &.delete-product-success {
        animation: move-from-left-to-right-delete 200ms ease-in-out forwards;
      }

      &.delete-product-error {
        animation: move-from-right-to-left-delete 500ms ease-in-out forwards;
      }
    }

    &-unavailable {
      display: block;
      grid-area: 2 / 2 / 2 / 4;
      padding-right: $petz-spacing-sm;
    }

    ptz-alert {
      grid-area: 2 / 1 / 3 / 4;
    }
  }
}

@media screen and (max-width: $petz-breakpoint-md) {
  ptz-header-drawer-bag-card {
    .ptz-header-drawer-bag-card {
      grid-template-columns: 1fr 10fr 1fr;

      .show-delete-button {
        right: 0;
        @include transition-translate(-4.0625rem);
      }

      &-image {
        max-width: $petz-spacing-4xl;
        max-height: $petz-spacing-4xl;
      }

      &-content {
        min-width: 13.4375rem;

        &-title {
          width: auto;
          font-size: $petz-font-size-2xs;
        }

        &-price {
          &-total {
            font-size: $petz-font-size-2xs;
          }
        }

        &-quantity {
          ptz-counter {
            width: 8.75rem;
          }
        }
      }

      &-trash-icon {
        padding-right: 0;
      }

      &-delete-button {
        &.show-delete-button {
          right: 0;
          transform: none;
          transition: right 300ms ease-out;
        }
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-sm) {
  ptz-header-drawer-bag-card {
    .ptz-header-drawer-bag-card {
      padding: $petz-spacing-md $petz-spacing-xs;

      &-delete-button {
        width: $petz-spacing-5xl;
        font-size: $petz-font-size-3xs;
      }

      &-content {
        padding: 0 $petz-spacing-2xs;

        &-variation {
          margin-left: $petz-spacing-2xs;
          padding: 0.875rem $petz-spacing-2xs;
        }

        &-quantity {
          ptz-counter {
            width: $petz-spacing-4xl * 2;

            .ptz-counter-sm {
              padding: 0.625rem $petz-spacing-2xs;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 362px){
  ptz-header-drawer-bag-card {
    .ptz-header-drawer-bag-card-content-variation p {
      font-size: $petz-font-size-3xs;
    }
  }
}
